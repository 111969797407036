/**
 * Fabrik Framework
 * @root fonts
*/

@import 'partials/_variables.scss';

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
       url('../fonts/montserrat-light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

html {
  font-size: 62.5%;
}

body {
  color: $baseFontColor;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: 200;
}

h1, .h1 {
  font-size: 2.6rem;
  text-transform: uppercase;

  @media (--l) {
    font-size: 3.6rem;
  }
}

h2, .h2 {
  font-size: 2.8rem;
}

h3, .h3 {
  font-size: 2.4rem;
}

h4, .h4 {
  font-size: 2rem;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-weight: 400;
  margin-top: 0;
  margin-bottom: .5em;
  line-height: 1;
}

a {
  color: $linkColor;
  text-decoration: none;
}

p {
  margin: 0 0 1em;
}
